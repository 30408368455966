import { parseEntityRef } from '@backstage/catalog-model';
import { InfoCard } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { EntityAboutCard, EntityLayout } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useEntity } from '@backstage/plugin-catalog-react';
import { BSBenchmark } from '@internal/backstage-plugin-models-common';
import PerformanceLevelsTable from '@internal/backstage-plugin-strategic-performance/src/components/PerformanceLevelsTable/PerformanceLevelsTable';
import { useBackstageApi } from '@internal/common';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { entityWarningContent } from '../common/EntityWarningContent';

export const UserJourneyPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const config = useApi(configApiRef);
  const [performanceLevels, setPerformanceLevels] = useState<BSBenchmark[]>([]);
  const { backstageFetch } = useBackstageApi();
  const { entity: userJourney } = useEntity();
  const [uncheckedFavorite, setUncheckedFavorite] = useState<boolean>(false);

  const systemIds = userJourney.relations
    ?.map((relation) => relation.targetRef)
    .map((ref) => parseEntityRef(ref))
    .map((ref) => ref.name);

  const hasSystems = !!systemIds?.length;

  if (!hasSystems) {
    return <InfoCard title="Systems">No Systems</InfoCard>;
  }

  useEffect(() => {
    getPerformanceLevelsBySystemsIds(systemIds);
  }, []);

  const getPerformanceLevelsBySystemsIds = async (systemIds: string[]) => {
    setLoading(true);

    const urlParams = new URLSearchParams();
    urlParams.append('systemsIds', systemIds.join(','));

    const performanceLevels = await backstageFetch<BSBenchmark[]>(
      `${config.getString(
        'backend.baseUrl',
      )}/api/strategic_performance/performance-levels?${urlParams}`,
    );

    if (performanceLevels) {
      setPerformanceLevels(performanceLevels);
    }
    setUncheckedFavorite(false);
    setLoading(false);
  };

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {entityWarningContent}
          <Grid md={6}>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid md={6}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid md={12}>
            {performanceLevels && (
              <InfoCard title="Strategic View">
                <PerformanceLevelsTable
                  loading={loading}
                  performanceLevels={performanceLevels}
                  favouritesOnly={false}
                  uncheckedFavorite={uncheckedFavorite}
                  setUncheckedFavorite={setUncheckedFavorite}
                />
              </InfoCard>
            )}
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};
