import { Box, CardContent, IconButton } from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BSSystem } from '@internal/backstage-plugin-models-common';
import { CustomSystemContent } from './CustomSystemContent';
import EditIcon from '@mui/icons-material/Edit';
import { InfoCard } from '@backstage/core-components';
import Tooltip from '@mui/material/Tooltip';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  cardButton: {
    borderRadius: '100px !important',
    color:
      theme.palette.mode === 'light'
        ? '#3643BA !important'
        : '##9CC9FF !important',
    margin: 'O 3px !important',
    padding: '5px !important',
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
  gridItemCardContentPadding: {
    padding: 0,
  },
  tabActive: {
    color: theme.palette.mode === 'light' ? blue[900] : '',
    cursor: 'pointer',
  },
  tabBorder: {
    backgroundColor: '#0d47a1',
  },
  tabInactive: {
    color: '#BDBDBD',
    cursor: 'pointer',
  },
}));

export function CustomSystemAboutCard() {
  const classes = useStyles();
  const cardClass = classes.gridItemCard;
  const cardContentClass = classes.gridItemCardContent;
  const cardContentClassPadding = classes.gridItemCardContentPadding;
  const buttonClass = classes.cardButton;
  const { entity: system } = useEntity<BSSystem>();
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const hasOffering = system.spec.links
    ? system.spec.links.filter(e => e.url.includes('support.decathlon'))
    : [];
  const [aboutOrShortcutsTab, setAboutOrShortcutsTab] =
    useState<string>('About');

  const isActiveTab = (tab: string) => {
    if (tab === aboutOrShortcutsTab) {
      return classes.tabActive;
    }

    return classes.tabInactive;
  };

  return (
    <InfoCard
      title={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            width: 200,
          }}
        >
          <Box
            className={isActiveTab('About')}
            onClick={() => setAboutOrShortcutsTab('About')}
          >
            About
          </Box>
          <Box
            className={isActiveTab('Shortcuts')}
            data-testid="shortcuts-tab"
            onClick={() => setAboutOrShortcutsTab('Shortcuts')}
          >
            Shortcuts
          </Box>
          <Box
            sx={{
              bottom: '-5px',
              left: `${aboutOrShortcutsTab.includes('About') ? '0' : '90px'}`,
              position: 'absolute',
            }}
          >
            <span
              className={classes.tabBorder}
              style={{
                display: 'flex',
                height: '2px',
                width: `${
                  aboutOrShortcutsTab.includes('About') ? '70px' : '110px'
                }`,
              }}
            />
          </Box>
        </Box>
      }
      headerProps={{
        action: (
          <Box sx={{ position: 'relative' }}>
            {aboutOrShortcutsTab.includes('Shortcuts') && (
              <Box
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  right: '5px',
                  top: '10px',
                }}
              >
                <Tooltip title="Add a shortcut">
                  <IconButton
                    className={buttonClass}
                    size="large"
                    data-testid="add-shortcut"
                    onClick={() => {
                      setIsAddModalOpen(true);
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit shortcuts">
                  <IconButton
                    className={buttonClass}
                    size="large"
                    onClick={() => {
                      setIsEditModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        ),
      }}
      deepLink={
        hasOffering.length
          ? {
              title: 'I have a request',
              link: hasOffering[0].url,
            }
          : undefined
      }
      cardClassName={cardClass}
      variant="gridItem"
      noPadding
    >
      <CardContent
        sx={{ paddingRight: '0' }}
        className={`${
          aboutOrShortcutsTab.includes('Shortcuts')
            ? cardContentClassPadding
            : cardContentClass
        }`}
      >
        <CustomSystemContent
          contentToDisplay={aboutOrShortcutsTab}
          system={system}
          isEditModalOpen={isEditModalOpen}
          isAddModalOpen={isAddModalOpen}
          onClose={() => {
            setIsAddModalOpen(false);
            setIsEditModalOpen(false);
          }}
          onOpenAddModal={() => setIsAddModalOpen(true)}
        />
      </CardContent>
    </InfoCard>
  );
}
