import { AlertMessage, configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  BSComponent,
  GraviteeComponentType,
} from '@internal/backstage-plugin-models-common';
import { CircularProgress, Grid2, Typography } from '@mui/material';
import { Entity, RELATION_API_PROVIDED_BY } from '@backstage/catalog-model';
import { FC, useEffect, useState } from 'react';

import { ApisTableComponent } from './ApisTableComponent';
import { RegisterDialog } from '../common/RegisterDialog';
import { SearchAPIs } from './SearchApis';
import { SnackBar } from '../common/SnackBar';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useBackstageApi } from '@internal/common';
import { useParams } from 'react-router-dom';

interface Props {
  open: boolean;
  onClose: () => void;
}
export const RegisterApisDialog: FC<Props> = ({ open, onClose }) => {
  const { name: componentName } = useParams();
  const { backstageFetch } = useBackstageApi();
  const config = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);

  const [apisToRemove, setApisToRemove] = useState<string[]>([]);

  const [providedApis, setProvidedApis] = useState<Entity[]>([]);
  const [message, setMessage] = useState<AlertMessage>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async () => {
    await submitRequest();
    onClose();
  };

  const getEntity = async () => {
    const apis = await catalogApi.getEntities({
      filter: {
        [`relations.${RELATION_API_PROVIDED_BY}`]: `component:default/${componentName}`,
      },
    });
    setProvidedApis(apis.items.filter(api => !!api));
  };

  async function syncGravitee(
    toAdd: string[],
    toRemove: string[],
  ): Promise<{ status: string; value: string }[]> {
    return await backstageFetch<{ status: string; value: string }[]>(
      `${config.getString('backend.baseUrl')}/api/gravitee/_map_idp_component`,
      {
        method: 'POST',
        body: {
          idpComponent: componentName,
          componentType: GraviteeComponentType.API,
          operations: [
            ...toAdd.map(api => ({
              operation: 'add',
              graviteeId: api,
            })),
            ...toRemove.map(api => ({ operation: 'remove', graviteeId: api })),
          ],
        },
      },
    );
  }

  async function syncBackstage(toAdd: string[], toRemove: string[]) {
    const updatedComponent = await backstageFetch<BSComponent>(
      `${config.getString('backend.baseUrl')}/api/catalog/components/${
        componentName ?? ''
      }/apis`,
      { method: 'PUT', body: { remove: toRemove, add: toAdd } },
    );
    if (updatedComponent) {
      setMessage({
        message: `${updatedComponent.metadata?.title} updated with selected APIs !`,
        severity: 'success',
      });
      setLoading(false);
    } else {
      setLoading(false);
      setMessage({
        message: 'No component created or updated',
        severity: 'error',
      });
    }
  }

  async function submitRequest() {
    try {
      setLoading(true);
      const toAdd = providedApis.map(api => api.metadata.name);
      await syncGravitee(toAdd, apisToRemove);
      await syncBackstage(toAdd, apisToRemove);
      setApisToRemove([]);
    } catch (error: any) {
      setLoading(false);
      setMessage({
        message: error.message,
        severity: 'error',
      });
    }
  }

  useEffect(() => {
    if (componentName) {
      getEntity();
    }
  }, [componentName]);

  return (
    <>
      <SnackBar message={message} setMessage={setMessage} />
      <RegisterDialog
        open={open}
        onClose={onClose}
        title="Manage APIs"
        onSubmit={handleSave}
        loading={loading}
      >
        <>
          <SearchAPIs
            providedApis={providedApis}
            onSelectApi={api => {
              setProvidedApis(prevProvidedApis => [...prevProvidedApis, api]);
              setApisToRemove(removedApis =>
                removedApis.filter(
                  removedApi => api.metadata.name !== removedApi,
                ),
              );
            }}
          />
          <ApisTableComponent
            selectedApis={
              providedApis.map(api => ({ ...api.metadata, ...api.spec })) ?? []
            }
            onRemove={removedApi => {
              setApisToRemove([...apisToRemove, removedApi]);
              setProvidedApis(prevProvidedApis =>
                prevProvidedApis.filter(
                  api => api.metadata.name !== removedApi,
                ),
              );
            }}
          />
          {loading ? (
            <Grid2
              size={12}
              sx={{
                mt: '2rem',
                textAlign: 'center',
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress
                  size={15}
                  color="inherit"
                  sx={{ mr: '1rem' }}
                />
                Linking provided APIs with a component usually takes up to 30
                seconds
              </Typography>
            </Grid2>
          ) : null}
        </>
      </RegisterDialog>
    </>
  );
};
