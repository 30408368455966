import {
  CustomGridRow,
  getApiNameCell,
  getDescsriptionCell,
  getLifecycleCell,
  getRemoveCell,
} from './apisTableComponent.utils';

import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { FC } from 'react';
import { FlatEntity } from '@internal/backstage-plugin-models-common';
import { GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material';

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    '.MuiDataGrid-container--top [role=row]': {
      backgroundColor: `${(theme.palette as any).dktBlue?.main} !important`,
      color: 'white',
    },
    'MuiButtonBase-root-MuiIconButton-root': {
      color: 'white',
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

interface ApisTableProps {
  selectedApis: FlatEntity[];
  // eslint-disable-next-line no-unused-vars
  onRemove: (removedApi: string) => void;
}

export const ApisTableComponent: FC<ApisTableProps> = ({
  selectedApis,
  onRemove,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'NAME',
      align: 'center',
      flex: 1,
      renderCell: getApiNameCell,
    },
    {
      field: 'lifecycle',
      headerName: 'LIFECYCLE',
      align: 'center',
      flex: 1,
      renderCell: getLifecycleCell,
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      align: 'center',
      flex: 1,
      renderCell: getDescsriptionCell,
    },
    {
      field: '',
      headerName: 'ACTIONS',
      align: 'center',
      flex: 0.5,
      renderCell: (params: CustomGridRow) => getRemoveCell(params, onRemove),
    },
  ];

  return (
    <div>
      {
        <StyledDataGrid
          disableVirtualization
          disableRowSelectionOnClick
          disableColumnMenu
          getRowId={row => row.name}
          rows={selectedApis}
          columns={columns}
          hideFooter
        />
      }
    </div>
  );
};
