import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useBackstageApi, useNotification } from '@internal/common';

import { Application } from '@internal/backstage-plugin-models-common';

type SearchApplicationsProps = {
  selectedApplications: Application[];
  // eslint-disable-next-line no-unused-vars
  onSelectApplication: (application: Application) => void;
};
export const SearchApplications = ({
  selectedApplications,
  onSelectApplication,
}: Readonly<SearchApplicationsProps>) => {
  const config = useApi(configApiRef);
  const { backstageFetch } = useBackstageApi();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [applications, setApplications] = useState<Application[]>([]);
  const [application, setApplication] = useState<Application | null>(null);
  const [loading, setLoading] = useState(false);
  const { setNotification } = useNotification();

  const fetchApplications = async (search?: string) => {
    try {
      setLoading(true);
      const query = search ? `&query=${search}` : '';
      const { data } = await backstageFetch<{ data: Application[] }>(
        `${config.getString(
          'backend.baseUrl',
        )}/api/gravitee/applications?page=1&size=20${query}`,
        {
          method: 'GET',
        },
      );
      const filteredApplications = data.filter(
        (appli: Application) =>
          !selectedApplications.some(a => a.id === appli.id),
      );
      setApplications(filteredApplications ?? []);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setApplications([]);
      setNotification({
        severity: 'error',
        message: error.message,
      });
    }
  };

  useEffect(() => {
    fetchApplications(search);
  }, [search]);

  const handleInputChange = (value: string) => {
    setSearch(value);
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-search-application"
      data-testid="combo-box-search-application"
      options={applications}
      blurOnSelect={true}
      value={application}
      onInputChange={(_, value) => handleInputChange(value)}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ marginY: '.5rem', width: 300 }}
      onChange={(_: SyntheticEvent<Element, Event>, value, reason) => {
        setApplication(value);
        if (reason === 'selectOption' && value) {
          setApplication(null);
          setApplications([]);
          setSearch('');
          onSelectApplication(value);
        }
      }}
      loading={loading}
      renderInput={params => (
        <TextField {...params} value={application} label="Search Application" />
      )}
    />
  );
};
