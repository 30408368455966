import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material/';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useBackstageApi, useNotification } from '@internal/common';

import { Entity } from '@backstage/catalog-model';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .v5-MuiPaper-root': {
    padding: '40px 100px',
  },
  '& .v5-MuiDialogTitle-root': {
    justifyContent: 'normal',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  '& .v5-MuiDialogActions-root': {
    width: 'calc(100% - 20px) !important',
  },
}));

interface DeleteEntityDialogProps {
  open: boolean;
  onClose?: () => void;
  entity: Entity;
  kind: string;
  redirect?: boolean;
}
export const DeleteEntityDialog = ({
  open,
  onClose,
  entity,
  kind,
  redirect,
}: DeleteEntityDialogProps) => {
  const config = useApi(configApiRef);
  const navigate = useNavigate();
  const { setNotification } = useNotification();
  const { backstageFetch } = useBackstageApi();
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);

    try {
      const response = await backstageFetch<Entity>(
        `${config.getString('backend.baseUrl')}/api/catalog/${
          kind === 'system' ? 'systems' : 'components'
        }/${entity.metadata.name}`,
        { method: 'DELETE' },
      );

      if (response) {
        setNotification({
          message: `${kind} deleted successfully`,
          severity: 'success',
        });
        redirect ? navigate('/catalog') : window.location.reload();
      }
    } catch (error: any) {
      setNotification({
        message: error.message,
        severity: 'error',
      });
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-register-apis"
      aria-describedby="dialog-register-apis-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="dialog-register-apis-title"
        display="flex"
        justifyContent="space-between"
      >
        <InfoOutlinedIcon sx={{ marginRight: '15px' }} />
        Are you sure you want to delete this {kind}?
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: '5px' }}>
          <Grid item xs={12}>
            <Typography sx={{ color: '#757575' }}>
              This entity does not seem to originate from a registered location.
              You therefore only have the option to delete it outright from the
              catalog.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          color="error"
          variant="contained"
          type="submit"
          disabled={isLoading}
          disableElevation
          loading={isLoading}
          onClick={submit}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
};
