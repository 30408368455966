export enum COMPONENT_TYPE {
  APPLIANCE = 'APPLIANCE',
  BACKEND_FOR_FRONTEND = 'BACKEND_FOR_FRONTEND',
  BATCH = 'BATCH',
  DECATHLON_API = 'DECATHLON_API',
  DESKTOP_APPLICATION = 'DESKTOP_APPLICATION',
  DOCUMENTATION = 'DOCUMENTATION',
  ERP = 'ERP',
  EXTERNAL_PROVIDER_API = 'EXTERNAL_PROVIDER_API',
  LIBRARY = 'LIBRARY',
  MOBILE_APPLICATION = 'MOBILE_APPLICATION',
  SAAS = 'SAAS',
  STREAMING = 'STREAMING',
  WEB_FRONTEND = 'WEB_FRONTEND',
}
