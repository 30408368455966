import {
  CustomGridRow,
  getApplicationNameCell,
  getDescsriptionCell,
  getOwnerCell,
  getRemoveCell,
} from './applicationsTable.utils';

import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { FC } from 'react';
import { FlatEntity } from '@internal/backstage-plugin-models-common';
import { GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material';

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    '.MuiDataGrid-container--top [role=row]': {
      backgroundColor: `${(theme.palette as any).dktBlue?.main} !important`,
      color: 'white',
    },
    '.MuiButtonBase-root-MuiIconButton-root': {
      color: 'white',
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  };
});

type ApplicationsTableProps = {
  currentSelectedApplications: FlatEntity[];
  // False positive from eslint
  // eslint-disable-next-line no-unused-vars
  onRemove: (removedAppication: string) => void;
};

export const ApplicationsTable: FC<ApplicationsTableProps> = ({
  currentSelectedApplications,
  onRemove,
  ...props
}) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NAME',
      align: 'center',
      flex: 1,
      renderCell: getApplicationNameCell,
    },
    {
      field: 'owner',
      headerName: 'OWNER',
      align: 'center',
      flex: 1,
      renderCell: getOwnerCell,
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      align: 'center',
      flex: 1,
      renderCell: getDescsriptionCell,
    },
    {
      field: '',
      headerName: '',
      align: 'center',
      flex: 0.5,
      renderCell: (params: CustomGridRow) => getRemoveCell(params, onRemove),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      {
        <StyledDataGrid
          disableVirtualization
          disableRowSelectionOnClick
          getRowId={row => `application-row-${row.id}`}
          rows={currentSelectedApplications}
          columns={columns}
          hideFooterPagination
          {...props}
        />
      }
    </div>
  );
};
