import { Content, Page } from '@backstage/core-components';

import { ConsumedApisCard } from '../../components/ConsumedApisCard/ConsumedApisCard';
import { Grid2 } from '@mui/material';
import { ProvidedApisCard } from '../../components/ProvidedApisCard/ProvidedApisCard';
import { useEntity } from '@backstage/plugin-catalog-react';
import { COMPONENT_TYPE } from '@internal/backstage-plugin-models-common';

export const ApiManagementPage = () => {
  const { entity } = useEntity();

  function isConsumedApiToShow() {
    return (
      entity?.spec?.type &&
      [
        COMPONENT_TYPE.DECATHLON_API,
        COMPONENT_TYPE.BACKEND_FOR_FRONTEND,
        COMPONENT_TYPE.EXTERNAL_PROVIDER_API,
      ].includes(entity.spec.type as COMPONENT_TYPE)
    );
  }

  return (
    <Page themeId="tool">
      <Content>
        <Grid2 container spacing={3} alignItems="stretch">
          {isConsumedApiToShow() ? (
            <Grid2 size={12}>
              <ProvidedApisCard />
            </Grid2>
          ) : (
            <></>
          )}
          <Grid2 size={12}>
            <ConsumedApisCard />
          </Grid2>
        </Grid2>
      </Content>
    </Page>
  );
};
