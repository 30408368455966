import { Button, Grid2 } from '@mui/material';
import {
  catalogApiRef,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';

import { ApiEntity } from '@backstage/catalog-model';
import { ApisTable } from '../common/ApisTable';
import { RegisterApplicationsDialog } from './RegisterApplicationsDialog';
import { useApi } from '@backstage/core-plugin-api';
import { useState } from 'react';

export const ConsumedApisCard = () => {
  const [openRegisterApplications, setOpenRegisterApplications] =
    useState(false);

  const catalogApi = useApi(catalogApiRef);

  const { entity } = useEntity();
  const [currentEntity, setCurrentEntity] = useState(entity);
  const { entities } = useRelatedEntities(currentEntity, {
    type: 'consumesApi',
    kind: 'api',
  });

  return (
    <>
      <RegisterApplicationsDialog
        open={openRegisterApplications}
        onClose={async () => {
          await catalogApi.refreshEntity(
            `component:default/${entity.metadata.name}`,
          );
          // Ensuring that the entity has been refreshed with a delay
          await new Promise(resolve => setTimeout(resolve, 1000));
          const updatedEntity = await catalogApi.getEntityByRef(
            `component:default/${entity.metadata.name}`,
          );
          updatedEntity && setCurrentEntity(updatedEntity);
          setOpenRegisterApplications(false);
        }}
      />
      <Grid2 container>
        <Grid2 size={12} sx={{ position: 'relative' }}>
          <Button
            variant="contained"
            color={undefined}
            onClick={() => setOpenRegisterApplications(true)}
            sx={{
              backgroundColor: '#1DB954!important',
              width: '210px',
              zIndex: 5,
              position: 'absolute !important',
              right: 20,
              top: 20,
            }}
          >
            Manage Application(s)
          </Button>
        </Grid2>
        <Grid2 size={12}>
          <ApisTable
            entities={entities as ApiEntity[]}
            title="Consumed APIs"
            emptyContentMessage="This component does not consume any APIs."
          />
        </Grid2>
      </Grid2>
    </>
  );
};
