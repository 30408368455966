import { Box, Card, Typography } from '@mui/material/';
import {
  ComponentEntity,
  Entity,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import { EntityTable, useEntity } from '@backstage/plugin-catalog-react';
import { LinkButton, TableColumn } from '@backstage/core-components';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BSSystem } from '@internal/backstage-plugin-models-common';
import { DeleteEntityDialog } from '@internal/common/src/ui/DKTDeleteEntityDialog/DeleteEntityDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const componentEntityHelpLink = '#';

const asComponentEntities = (entities: Entity[]): ComponentEntity[] =>
  entities as ComponentEntity[];

export function CustomComponentCard() {
  const { entity: system } = useEntity<BSSystem>();
  const componentEntityColumns: TableColumn<ComponentEntity>[] = [
    EntityTable.columns.createEntityRefColumn({ defaultKind: 'component' }),
    EntityTable.columns.createSystemColumn(),
    EntityTable.columns.createOwnerColumn(),
    EntityTable.columns.createSpecTypeColumn(),
    EntityTable.columns.createSpecLifecycleColumn(),
    {
      title: 'Delete',
      field: 'delete',
      render: (rowData: ComponentEntity) => (
        <Box
          onClick={() => {
            setOpenDialog(true);
            setEntityToDelete(rowData);
          }}
          sx={{ cursor: 'pointer' }}
          data-testid="delete-component"
        >
          <DeleteIcon color="primary" />
        </Box>
      ),
    },
  ];
  const [openDialog, setOpenDialog] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<Entity>();
  const useStyles = makeStyles(theme => ({
    cardButton: {
      borderRadius: '5px !important',
      textTransform: 'capitalize',
      marginLeft: '15px !important',
      padding: '5px 15px',
      ['&:hover']: {
        backgroundColor: '#E1E3F5 !important',
        color: theme.palette.primary.main + ' !important',
      },
    },
  }));

  const classes = useStyles();
  const buttonClass = classes.cardButton;

  return (
    <Card>
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" component="h2">
          Components
        </Typography>
        <LinkButton
          to={`/register/systems/${system.metadata.name}/add-component`}
          state={{ system: system }}
          color="primary"
          variant="contained"
          className={buttonClass}
        >
          <AddCircleIcon style={{ marginRight: 5 }} />
          Add components
        </LinkButton>
      </Grid>
      <RelatedEntitiesCard
        variant="gridItem"
        title={'Components'} // title is normally of type string but the actual use allows any kind of JSX component
        entityKind="Component"
        relationType={RELATION_HAS_PART}
        columns={componentEntityColumns}
        emptyMessage="No component is part of this system"
        emptyHelpLink={componentEntityHelpLink}
        asRenderableEntities={asComponentEntities}
        tableOptions={{
          showTitle: false,
          toolbar: false,
        }}
      />
      <DeleteEntityDialog
        kind="component"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        entity={entityToDelete!}
        redirect={false}
      />
    </Card>
  );
}
