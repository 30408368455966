import { CardTab, TabbedCard } from '@backstage/core-components';
import { OpenApiDefinitionWidget } from '@backstage/plugin-api-docs';
import { useBackstageApi } from '@internal/common';
import { useEntity } from '@backstage/plugin-catalog-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useEffect, useState } from 'react';
import { ErrorTabbedCard } from './ErrorTabbedCard';

type OpenapiResult = { openapiName: string; content: string };

export function OpenApiCard() {
  const { backstageFetch } = useBackstageApi();
  const { entity } = useEntity();
  const config = useApi(configApiRef);
  const [swagger, setSwagger] = useState<OpenapiResult[]>([]);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const id = entity.metadata.name;
    backstageFetch<
      OpenapiResult[] | { error: string; error_description: string }
    >(`${config.getString('backend.baseUrl')}/api/quapi/apis/${id}/openapis`, {
      method: 'GET',
    }).then((data) => {
      if ('error' in data) {
        setError(data.error_description);
        setSwagger([]);
        return;
      }
      setSwagger(data);
      setError(null);
    });
  }, [entity, backstageFetch]);

  if (error) {
    return <ErrorTabbedCard error={error} />;
  }

  return (
    <TabbedCard>
      {swagger.map((s) => (
        <CardTab label={s.openapiName} key={s.openapiName}>
          <OpenApiDefinitionWidget definition={s.content} />
        </CardTab>
      ))}
    </TabbedCard>
  );
}
