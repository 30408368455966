import { Button, IconButton, Link, styled } from '@mui/material';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';
import {
  EntityDisplayName,
  EntityRefLink,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { FC, useEffect, useState } from 'react';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { DKTLevelChip } from '@internal/common';
import LaunchIcon from '@mui/icons-material/Launch';

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    '.MuiDataGrid-columnHeaders > div:first-child': {
      BorderColor: 'rgb(54, 67, 186)',
      borderRadius: '8px 8px 0px 0px',
    },
    '.MuiDataGrid-virtualScrollerContent': {
      backgroundColor: '#FFFFFF !important',
    },
    '.MuiDataGrid-overlay': {
      backgroundColor: '#FFFFFF !important',
    },
    '.v5-MuiToolbar-gutters': {
      backgroundColor: '#FFFFFF',
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'center',
    },
    '.MuiDataGrid-container--top [role=row]': {
      background: `${(theme.palette as any).dktBlue?.main} !important`,
      color: 'white',
    },
    '.MuiButtonBase-root-MuiIconButton-root': {
      color: 'white',
    },
  };
});

interface Props extends Omit<DataGridProps, 'columns'> {
  performanceLevels: any[];
  favouritesOnly: boolean;
  uncheckedFavorite: boolean;
  setUncheckedFavorite: (uncheckedFavorite: boolean) => void;
}

export const PerformanceLevelsTable: FC<Props> = ({
  performanceLevels,
  favouritesOnly,
  uncheckedFavorite,
  setUncheckedFavorite,
  ...props
}) => {
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const [favouritesPerformanceLevels, setFavouritesPerformanceLevels] =
    useState([]);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'SYSTEM',
      align: 'center',
      renderCell: ({ value, row }) => {
        return (
          <Link
            sx={{ fontWeight: 'bold' }}
            href={`/catalog/default/system/${row.id}`}
          >
            {value}
          </Link>
        );
      },
      flex: 1,
    },
    {
      field: 'domain',
      headerName: 'DOMAIN',
      align: 'center',
      renderCell: ({ value }) => {
        return (
          value && (
            <EntityRefLink entityRef={value}>
              <EntityDisplayName hideIcon={true} entityRef={value} />
            </EntityRefLink>
          )
        );
      },
      flex: 1,
    },
    {
      field: 'subdomain',
      headerName: 'SUBDOMAIN',
      align: 'center',
      renderCell: ({ value }) => {
        return (
          value && (
            <EntityRefLink entityRef={value}>
              <EntityDisplayName hideIcon={true} entityRef={value} />
            </EntityRefLink>
          )
        );
      },
      flex: 1,
    },
    {
      field: 'businessCriticalityLevel',
      headerName: 'BUSINESS CRITICALITY',
      align: 'center',
      flex: 1,
    },
    {
      align: 'center',
      description: 'Accelerate level',
      disableColumnMenu: true,
      field: 'accelerate',
      headerName: 'ACCELERATE',
      renderCell: ({ value }) => {
        return <DKTLevelChip level={value} size="small" />;
      },
      sortable: false,
      flex: 1,
    },
    {
      align: 'center',
      description: 'Architecture level',
      disableColumnMenu: true,
      field: 'architecture',
      headerName: 'ARCHITECTURE',
      renderCell: ({ value }) => {
        return <DKTLevelChip level={value} size="small" />;
      },
      sortable: false,
      flex: 1,
    },
    {
      align: 'center',
      description: 'Code level',
      disableColumnMenu: true,
      field: 'code',
      headerName: 'CODE',
      renderCell: ({ value }) => {
        return <DKTLevelChip level={value} size="small" />;
      },
      sortable: false,
      flex: 1,
    },
    {
      align: 'center',
      description: 'Data exchange level',
      disableColumnMenu: true,
      field: 'dataExchange',
      headerName: 'DATA EXCHANGE',
      renderCell: ({ value }) => {
        return <DKTLevelChip level={value} size="small" />;
      },
      sortable: false,
      flex: 1,
    },
    {
      align: 'center',
      description: 'Operation level',
      disableColumnMenu: true,
      field: 'operation',
      headerName: 'OPERATION',
      renderCell: ({ value }) => {
        return <DKTLevelChip level={value} size="small" />;
      },
      flex: 1,
    },
    {
      align: 'center',
      description: 'Security level',
      disableColumnMenu: true,
      field: 'security',
      headerName: 'SECURITY',
      renderCell: ({ value }) => {
        return <DKTLevelChip level={value} size="small" />;
      },
      flex: 1,
    },
    {
      align: 'center',
      disableColumnMenu: true,
      field: 'actions',
      headerName: 'ACTIONS',
      renderCell: ({ row }) => {
        return (
          <>
            <Button
              onClick={() => {
                toggleStarredEntity('system:default/' + row.id);
                setUncheckedFavorite(true);
              }}
              data-testid="bookmark-button"
              startIcon={
                isStarredEntity('system:default/' + row.id) ? (
                  <BookmarkIcon sx={{ color: '#FF9800' }} />
                ) : (
                  <BookmarkBorderIcon />
                )
              }
            ></Button>
            <IconButton
              onClick={() =>
                window.open(
                  `https://delivery-metrics.decathlon.net/scope/benchmark?uuid=${row.id}`,
                  '_blank',
                )
              }
              aria-label="View System on DM"
              title="View System on DM"
            >
              <LaunchIcon />
            </IconButton>
          </>
        );
      },
      sortable: false,
      flex: 1,
    },
  ];

  function getFavouritesPerformanceLevels() {
    if (favouritesOnly) {
      const filteredPerformanceLevels = performanceLevels.filter(
        performanceLevel => {
          return isStarredEntity('system:default/' + performanceLevel.id);
        },
      );
      setFavouritesPerformanceLevels(filteredPerformanceLevels as any);
    }
  }

  useEffect(() => {
    getFavouritesPerformanceLevels();
  }, [favouritesOnly, uncheckedFavorite, performanceLevels]);

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <StyledDataGrid
        rows={favouritesOnly ? favouritesPerformanceLevels : performanceLevels}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[50, 100]}
        sx={{
          borderRadius: '9px',
          border: 'none',
        }}
        {...props}
      />
    </div>
  );
};

export default PerformanceLevelsTable;
