import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { ApiExplorerPage, apiDocsPlugin } from '@backstage/plugin-api-docs';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {
  DktCatalogPage,
  RegisterToCatalog,
} from '@internal/backstage-plugin-dkt_catalog/src/plugin';
import { IdentityApi, configApiRef, useApi } from '@backstage/core-plugin-api';
import { Navigate, Route } from 'react-router-dom';
import { NotificationProvider, SimpleNotification } from '@internal/common';

import BrandIconGrey from './components/Root/LogoSimpleGrey';
import { CapabilityPage } from '@internal/backstage-plugin-capability-map';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { CssBaseline } from '@mui/material';
import { QuadrantPage } from '@internal/backstage-plugin-quadrant';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { Root } from './components/Root';
import { SearchPage } from '@backstage/plugin-search';
import { StrategicPerformanceViewPage } from '@internal/backstage-plugin-strategic-performance';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import { UnifiedThemeProvider } from '@backstage/theme';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { createApp } from '@backstage/app-defaults';
import { customActionsProps } from '@internal/backstage-plugin-custom-api-management/src/components/common/customActionsProps';
import { customTheme } from './theme';
import { datadogRum } from '@datadog/browser-rum';
import { decathlonSSOAuthProviderApiRef } from '@internal/backstage-plugin-sso-dkt';
import { entityPage } from './components/catalog/pages/EntityPage';
import { icons } from '@internal/backstage-plugin-custom-system-overview';
import { orgPlugin } from '@backstage/plugin-org';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { searchPage } from './components/search/SearchPage';
import { techdocsPlugin } from '@backstage/plugin-techdocs';

const oauthProvider: SignInProviderConfig = {
  id: 'oauth-auth-provider',
  title: 'Sign In',
  message: 'Sign in using OAuth2',
  apiRef: decathlonSSOAuthProviderApiRef,
};

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: false,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  icons: { ...icons },
  components: {
    SignInPage: (props) => (
      <SignInPage
        {...props}
        providers={[oauthProvider]}
        onSignInSuccess={async (identityApi: IdentityApi) => {
          const credentials = await identityApi.getCredentials();
          const token = parseJwt(credentials.token!);
          datadogRum.setUser({ id: token.uuid });
          datadogRum.startSessionReplayRecording();
          props.onSignInSuccess(identityApi);
        }}
      />
    ),
  },
  themes: [
    {
      id: 'decathlon',
      title: 'Decathlon',
      variant: 'light',
      icon: <BrandIconGrey />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={customTheme} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <DktCatalogPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/register" element={<RegisterToCatalog />} />
    <Route
      path="/api-docs"
      element={<ApiExplorerPage actions={customActionsProps() as any} />}
    />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/capability-map" element={<CapabilityPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/strategic-view" element={<StrategicPerformanceViewPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            showFilters: true,
            rootEntityRefs: ['group:decathlon'],
            selectedKinds: ['System', 'Group', 'Component'],
            maxDepth: 2,
          }}
          maxDepth={2}
          rootEntityNames={{
            kind: 'Group',
            namespace: 'default',
            name: 'decathlon',
          }}
        />
      }
    />
    <Route path="/quadrant" element={<QuadrantPage />} />
  </FlatRoutes>
);

const DatadogRumComponent = () => {
  const config = useApi(configApiRef);

  datadogRum.init({
    applicationId: config.getString('datadogRum.applicationId'),
    clientToken: config.getString('datadogRum.clientToken'),
    site: 'datadoghq.eu',
    service: 'idp-backstage',
    env: config.getString('datadogRum.env'),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: config.getNumber('datadogRum.sessionSampleRate'),
    sessionReplaySampleRate: config.getNumber(
      'datadogRum.sessionReplaySampleRate',
    ),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    startSessionReplayRecordingManually: true,
    defaultPrivacyLevel: 'allow',
  });

  return <></>;
};

export default app.createRoot(
  <NotificationProvider>
    <SimpleNotification />
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
    <DatadogRumComponent />
  </NotificationProvider>,
);

function parseJwt(token: string) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}
