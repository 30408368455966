import { SearchAutocomplete, useSearch } from '@backstage/plugin-search-react';
import { useEffect, useState } from 'react';

import { CatalogApiEntityDocument } from '@internal/backstage-plugin-search-backend-module-custom-collator-kind-api';
import { Entity } from '@backstage/catalog-model';

type SearchAPIsProps = {
  providedApis: Entity[];
  // eslint-disable-next-line no-unused-vars
  onSelectApi: (api: Entity) => void;
};

export const SearchAPIs = ({
  providedApis,
  onSelectApi,
}: Readonly<SearchAPIsProps>) => {
  const { result } = useSearch();
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const collatorToEntity = (document: CatalogApiEntityDocument): Entity => {
    return {
      kind: document.kind,
      apiVersion: '',
      metadata: {
        name: document.name,
        title: document.title,
        description: document.text,
        namespace: document.namespace,
      },
      spec: {
        owner: document.owner,
        system: document.system,
        lifecycle: document.lifecycle,
        type: document.type,
      },
    };
  };

  useEffect(() => {
    if (result.value?.results) {
      const options =
        result.value?.results.map((res: any) => res.document) ??
        ([] as CatalogApiEntityDocument[]);
      const filteredOptions = options.filter(
        (option: any) =>
          !providedApis.some(a => a.metadata.title === option.title),
      );
      setSearchOptions(filteredOptions);
    }
  }, [result.value?.results]);

  return (
    <SearchAutocomplete
      data-testid="combo-box-search-api"
      inputPlaceholder="Search API"
      options={searchOptions}
      getOptionLabel={option => option.title}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onBlur={() => setInputValue('')}
      clearOnBlur
      onChange={(_, value) => {
        setInputValue('');
        if (value) {
          onSelectApi(collatorToEntity(value));
        }
      }}
    />
  );
};
