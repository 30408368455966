import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';

import { ApiManagementPage } from '@internal/backstage-plugin-custom-api-management/src/pages/api-management/index';
import { COMPONENT_TYPE } from '@internal/backstage-plugin-models-common';
import { DKTEntityLayout } from '@internal/common';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import { EntityLayout } from '@backstage/plugin-catalog';
import { cicdContent } from '../common/CicdContent';
import { overviewContent } from '../common/OverviewContent';
import { useEntity } from '@backstage/plugin-catalog-react';

export const ComponentPage = () => {
  const { entity } = useEntity();

  function isComponentTypeForApi(): boolean {
    return (
      !!entity?.spec?.type &&
      [
        COMPONENT_TYPE.DECATHLON_API,
        COMPONENT_TYPE.BACKEND_FOR_FRONTEND,
        COMPONENT_TYPE.EXTERNAL_PROVIDER_API,
        COMPONENT_TYPE.BATCH,
        COMPONENT_TYPE.STREAMING,
        COMPONENT_TYPE.MOBILE_APPLICATION,
        COMPONENT_TYPE.SAAS,
        COMPONENT_TYPE.ERP,
        COMPONENT_TYPE.APPLIANCE,
        COMPONENT_TYPE.DESKTOP_APPLICATION,
      ].includes(entity.spec.type as COMPONENT_TYPE)
    );
  }

  return (
    <DKTEntityLayout>
      {/*for all component type*/}
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/ci-cd" title="CI/CD">
        {cicdContent}
      </EntityLayout.Route>
      <EntityLayout.Route path="/code-insights" title="Code Insights">
        <EntityGithubInsightsContent />
      </EntityLayout.Route>
      <EntityLayout.Route path="/adrs" title="ADRs" if={isAdrAvailable}>
        <EntityAdrContent />
      </EntityLayout.Route>

      {isComponentTypeForApi() ? (
        <EntityLayout.Route path="/api" title="API">
          <ApiManagementPage />
        </EntityLayout.Route>
      ) : (
        <></>
      )}
    </DKTEntityLayout>
  );
};
