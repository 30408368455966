import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';

export type CustomGridRow = GridRenderCellParams<
  any,
  any,
  any,
  GridTreeNodeWithRender
>;

export const getApplicationNameCell = ({ row }: CustomGridRow) => {
  return row.name ? row.name : '-';
};

export const getOwnerCell = ({ row }: CustomGridRow) => {
  return row.displayName ? row.displayName : '-';
};

export const getDescsriptionCell = ({ row }: CustomGridRow) => {
  return row.description ?? '-';
};
export const getRemoveCell = (
  { row }: CustomGridRow,
  // eslint-disable-next-line no-unused-vars
  onRemove: (applicationId: string) => void,
) => {
  return (
    <IconButton aria-label="remove" onClick={() => onRemove(row.id)}>
      <DeleteOutline />
    </IconButton>
  );
};
