import { AlertMessage, configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Application,
  BSComponent,
  GraviteeComponentType,
} from '@internal/backstage-plugin-models-common';
import { FC, useEffect, useState } from 'react';

import { ApplicationsTable } from './ApplicationsTable';
import { RegisterDialog } from '../common/RegisterDialog';
import { SearchApplications } from './SearchApplications';
import { SnackBar } from '../common/SnackBar';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useBackstageApi } from '@internal/common';
import { useParams } from 'react-router-dom';

interface Props {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: () => Promise<void> | void;
}
export const RegisterApplicationsDialog: FC<Props> = ({ open, onClose }) => {
  const { name: componentName } = useParams();
  const { backstageFetch } = useBackstageApi();

  const config = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);

  const [currentApplications, setCurrentApplications] = useState<string[]>([]);
  const [applicationsInTable, setapplicationsInTable] = useState<Application[]>(
    [],
  );
  const [message, setMessage] = useState<AlertMessage>();
  const [loading, setLoading] = useState<boolean>(false);

  const getEntity = async () => {
    const component = await catalogApi.getEntityByRef(
      stringifyEntityRef({
        kind: 'component',
        name: componentName ?? '',
      }),
    );

    if (
      component?.metadata?.annotations &&
      component.metadata.annotations?.[
        'decathlon.net/gravitee-applications'
      ] !== ''
    ) {
      const applicationsOfComponent =
        component.metadata?.annotations?.[
          'decathlon.net/gravitee-applications'
        ]?.split(',') ?? undefined;
      if (!applicationsOfComponent) {
        setapplicationsInTable([]);
        return;
      }
      setCurrentApplications(applicationsOfComponent);
      let applis: Application[] = [];
      for (const applicationId of applicationsOfComponent) {
        const appli = await backstageFetch<Application>(
          `${config.getString(
            'backend.baseUrl',
          )}/api/gravitee/applications/${applicationId}`,
          {
            method: 'GET',
          },
        );
        if (appli) {
          applis.push(appli);
        }
      }

      setapplicationsInTable(applis);
    }
  };

  async function syncGravitee(): Promise<{ status: string; value: string }[]> {
    const toRemove = currentApplications.filter(apiName => {
      const apis = applicationsInTable.map(a => a.id);
      return !apis.includes(apiName);
    });

    return await backstageFetch<{ status: string; value: string }[]>(
      `${config.getString('backend.baseUrl')}/api/gravitee/_map_idp_component`,
      {
        method: 'POST',
        body: {
          idpComponent: componentName,
          componentType: GraviteeComponentType.APPLICATION,
          operations: [
            ...applicationsInTable.map(appli => ({
              operation: 'add',
              graviteeId: appli.id,
            })),
            ...toRemove.map(appliId => ({
              operation: 'remove',
              graviteeId: appliId,
            })),
          ],
        },
      },
    );
  }

  const submitRequest = async () => {
    try {
      setLoading(true);
      const syncGraviteeResult = await syncGravitee();
      const updatedApplis = syncGraviteeResult
        ?.filter(r => r.status === 'fulfilled')
        ?.map(r => r.value);
      const applications = applicationsInTable
        ?.filter(appli => updatedApplis.some((u: any) => u === appli.id))
        ?.map(a => a.id);

      await backstageFetch<BSComponent>(
        `${config.getString('backend.baseUrl')}/api/catalog/components/${
          componentName ?? ''
        }/applications`,
        {
          method: 'PUT',
          body: { applications },
        },
      );
      setLoading(false);
      setMessage({
        message: 'Application(s) linked and consumed APIs retrieved',
        severity: 'success',
      });
      onClose();
    } catch (error: any) {
      onClose();
      setLoading(false);
      setMessage({
        message: error.message,
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    if (componentName) {
      getEntity();
    }
  }, [componentName]);

  return (
    <>
      <SnackBar message={message} setMessage={setMessage} />
      <RegisterDialog
        open={open}
        onClose={() => onClose()}
        title="Manage Applications"
        onSubmit={submitRequest}
        loading={loading}
      >
        <>
          <SearchApplications
            selectedApplications={applicationsInTable}
            onSelectApplication={api => {
              setapplicationsInTable(prevProvidedApis => [
                ...prevProvidedApis,
                api,
              ]);
            }}
          />
          <ApplicationsTable
            currentSelectedApplications={
              applicationsInTable.map(api => ({ ...api.owner, ...api })) ?? []
            }
            onRemove={removedAppli => {
              setapplicationsInTable(prevApplisOnTable =>
                prevApplisOnTable.filter(appli => appli.id !== removedAppli),
              );
            }}
          />
        </>
      </RegisterDialog>
    </>
  );
};
