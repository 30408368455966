import { configApiRef, useApi } from '@backstage/core-plugin-api';

import { Entity } from '@backstage/catalog-model';
import { useBackstageApi } from './use-backstage-api';
import { useNotification } from '../contexts';

export const useRefresh = (
  entityName: string,
  options: { synchroniseUrl: string },
) => {
  const { synchroniseUrl } = options;
  const config = useApi(configApiRef);
  const { backstageFetch } = useBackstageApi();
  const { setNotification } = useNotification();

  const synchroniseEntity = async () => {
    try {
      const entity = await backstageFetch<Entity>(
        `${config.getString('backend.baseUrl')}${synchroniseUrl}${entityName}`,
        { method: 'GET' },
      );
      if (entity) {
        setNotification({
          message: `${
            entity.metadata.title ?? entity.metadata.name
          } successfully refreshed`,
          severity: 'success',
        });
      }
    } catch (error: any) {
      setNotification({
        message: error.message,
        severity: 'error',
      });
    }
  };

  return {
    synchroniseEntity,
  };
};
