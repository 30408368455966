import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { indexRouteRef } from './routes';

export const capabilityMapPlugin = createPlugin({
  id: 'capability_map',
  routes: {
    index: indexRouteRef,
  },
});

export const CapabilityPage = capabilityMapPlugin.provide(
  createRoutableExtension({
    name: 'CapabilityPage',
    component: () => import('./Pages').then(m => m.IndexPage),
    mountPoint: indexRouteRef,
  }),
);
