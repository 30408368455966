import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { FC } from 'react';

interface Props {
  children: JSX.Element;
  open: boolean;
  title: string;
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
export const RegisterDialog: FC<Props> = ({
  children,
  open,
  onClose,
  title,
  onSubmit,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-register"
      aria-describedby="dialog-register-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="dialog-register-title"
        display="flex"
        justifyContent="space-between"
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ height: '70vh', minHeight: '70vh' }}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
