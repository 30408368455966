import { InferType, array, mixed, object, string } from 'yup';

import { LIFECYCLE } from '../../enums';

export type IDPBackProduct = {
  reference: string;
  name: string;
  description: string;
  support_group: string;
  owner: string;
  backup_owner: string;
  lifecycle: LIFECYCLE;
  domain: string;
  sub_domain: string;
  budget?: string;
  business_criticality_score: string;
  sources: {
    key: string;
    value: string;
  }[];
  links: {
    title: string;
    url: string;
  }[];
  business_criticality_level: string;
};

export const ShortcutLinkSchema = object({
  title: string().required(),
  url: string()
    .required()
    .url()
    .when('title', {
      is: (title: string) => title?.includes('Smax'),
      then: () =>
        string().matches(
          /^https:\/\/support.decathlon.net\/.*/,
          'Url should contain https://support.decathlon.net/',
        ),
    }),
});

export type ShortcutLink = InferType<typeof ShortcutLinkSchema>;

export const CreateProductSchema = object({
  backup_owner: string().required(),
  budget: string()
    .optional()
    .nullable()
    .matches(/^\d{0,9}$|^$/, 'Budget must be between 0 and 999999999'),
  business_criticality_score: string()
    .matches(
      /(^\d\d?$|^100$)|^$/,
      'Business Criticality Score must be contained between 0 and 100',
    )
    .optional(),
  description: string().optional(),
  lifecycle: mixed<LIFECYCLE>()
    .oneOf(Object.values(LIFECYCLE))
    .required('Lifecycle is required'),
  links: array().of(ShortcutLinkSchema).optional(),
  smax_services: array(string().required()).optional(),
  maturity_matrix_source: string().optional(),
  name: string()
    .matches(
      /^[a-zA-Z0-9-\s]*$/,
      'Only alphabets, numbers, spaces and hyphens are allowed',
    )
    .min(3, 'System name must be at least 3 characters long')
    .required(),
  owner: string().required(),
  sma_x_support_offering_link: string()
    .url()
    .matches(
      /^$|^(https:\/\/support.decathlon.net\/).*/,
      'Url should contain https://support.decathlon.net/',
    ),
  support_group: string().required(),
});

export const CreateShortcutsSchema = object({
  shortcuts: array().of(ShortcutLinkSchema).required(),
});
export type CreateShortcutsDTO = InferType<typeof CreateShortcutsSchema>;

export type CreateProductDTO = InferType<typeof CreateProductSchema>;
export type UpdateProductDTO = Partial<CreateProductDTO>;
