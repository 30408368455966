import {
  CodeSnippet,
  InfoCard,
  InfoCardVariants,
  Progress,
  TableColumn,
  TableOptions,
  WarningPanel,
} from '@backstage/core-components';

import { ApiEntity } from '@backstage/catalog-model';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { Typography } from '@mui/material';
import { apiEntityColumns } from './presets';

export const ApisTable = (props: {
  variant?: InfoCardVariants;
  title?: string;
  columns?: TableColumn<ApiEntity>[];
  tableOptions?: TableOptions;
  entities: ApiEntity[];
  loading?: boolean;
  error?: any;
  emptyContentMessage: string;
}) => {
  const {
    variant = 'gridItem',
    title = 'Provided APIs',
    columns = apiEntityColumns,
    tableOptions = {},
    entities,
    loading = false,
    error,
    emptyContentMessage,
  } = props;

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error || !entities) {
    return (
      <InfoCard variant={variant} title={title}>
        <WarningPanel
          severity="error"
          title="Could not load APIs"
          message={<CodeSnippet text={`${error}`} language="text" />}
        />
      </InfoCard>
    );
  }

  return (
    <EntityTable
      title={title}
      variant={variant}
      emptyContent={
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">{emptyContentMessage}</Typography>
        </div>
      }
      columns={columns}
      tableOptions={tableOptions}
      entities={entities as ApiEntity[]}
    />
  );
};
