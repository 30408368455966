import { Alert, IconButton } from '@mui/material';
import { Box, Breadcrumbs, TabProps, makeStyles } from '@material-ui/core';
import {
  Content,
  Header,
  Page,
  Progress,
  RoutedTabs,
} from '@backstage/core-components';
import {
  DEFAULT_NAMESPACE,
  Entity,
  EntityRelation,
} from '@backstage/catalog-model';
import {
  EntityDisplayName,
  EntityRefLink,
  catalogApiRef,
  entityRouteRef,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import {
  attachComponentData,
  createExternalRouteRef,
  createRouteRef,
  useApi,
  useElementFilter,
  useRouteRefParams,
} from '@backstage/core-plugin-api';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useAsync from 'react-use/esm/useAsync';

export const unregisterRedirectRouteRef = createExternalRouteRef({
  id: 'catalog:unregister-redirect',
  optional: true,
});

export const rootRouteRef = createRouteRef({
  id: 'catalog',
});

export type DKTEntityLayoutRouteProps = {
  path: string;
  title: string;
  children: JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

export interface DKTEntityLayoutProps {
  children?: React.ReactNode;
  /**
   * An array of relation types used to determine the parent entities in the hierarchy.
   * These relations are prioritized in the order provided, allowing for flexible
   * navigation through entity relationships.
   *
   * For example, use relation types like `["partOf", "memberOf", "ownedBy"]` to define how the entity is related to
   * its parents in the Entity Catalog.
   *
   * It adds breadcrumbs in the Entity page to enhance user navigation and context awareness.
   */
  parentEntityRelations?: string[];
}

const dataKey = 'plugin.catalog.entityLayoutRoute';
const Route: (props: DKTEntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true); // This causes all mount points that are discovered within this route to use the path of the route itself

export const DKTEntityLayout = (props: DKTEntityLayoutProps) => {
  const { children, parentEntityRelations } = props;
  const { kind, namespace, name } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const useStyles = makeStyles(theme => ({
    breadcrumbs: {
      color: theme.page.fontColor,
      fontSize: theme.typography.caption.fontSize,
      textTransform: 'uppercase',
      marginTop: theme.spacing(1),
      opacity: 0.8,
      '& span ': {
        color: theme.page.fontColor,
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
      },
    },
  }));
  const classes = useStyles();

  const { headerTitle, headerType } = headerProps(
    kind,
    namespace,
    name,
    entity,
  );
  const parentEntity = findParentRelation(
    entity?.relations ?? [],
    parentEntityRelations ?? [],
  );
  const catalogApi = useApi(catalogApiRef);

  const { value: ancestorEntity } = useAsync(async () => {
    if (parentEntity) {
      return findParentRelation(
        (await catalogApi.getEntityByRef(parentEntity?.targetRef))?.relations,
        parentEntityRelations,
      );
    }
    return null;
  }, [parentEntity]);
  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError:
            'Child of EntityLayout must be an EntityLayout.Route',
        })
        .getElements<DKTEntityLayoutRouteProps>() // all nodes, element data, maintain structure or not?
        .flatMap(({ props: elementProps }) => {
          if (!entity || (elementProps.if && !elementProps.if(entity))) {
            return [];
          }

          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
            },
          ];
        }),
    [entity],
  );

  function findParentRelation(
    entityRelations: EntityRelation[] = [],
    relationTypes: string[] = [],
  ) {
    for (const type of relationTypes) {
      const foundRelation = entityRelations.find(
        relation => relation.type === type,
      );
      if (foundRelation) {
        return foundRelation; // Return the first found relation and stop
      }
    }
    return null;
  }

  function headerProps(
    paramKind: string | undefined,
    paramNamespace: string | undefined,
    paramName: string | undefined,
    entity: Entity | undefined,
  ): { headerTitle: string; headerType: string } {
    const kind = paramKind ?? entity?.kind ?? '';
    const namespace = paramNamespace ?? entity?.metadata.namespace ?? '';
    const name =
      entity?.metadata.title ?? paramName ?? entity?.metadata.name ?? '';

    return {
      headerTitle: `${name}${
        namespace && namespace !== DEFAULT_NAMESPACE ? ` in ${namespace}` : ''
      }`,
      headerType: (() => {
        let t = kind.toLocaleLowerCase('en-US');
        if (entity?.spec?.type) {
          t += ' — ';
          t += (entity.spec as { type: string }).type.toLocaleLowerCase(
            'en-US',
          );
        }
        return t;
      })(),
    };
  }

  function EntityLayoutTitle(props: {
    readonly title: string;
    readonly entity: Entity | undefined;
  }) {
    const { entity, title } = props;
    return (
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            position: 'absolute',
            top: -15,
            zIndex: 100,
          }}
        >
          <IconButton onClick={() => window.history.back()}>
            <ArrowBackIosIcon color="primary" />
          </IconButton>
        </Box>
        <Box
          display="inline-flex"
          alignItems="center"
          height="1em"
          maxWidth="100%"
        >
          <Box
            component="span"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {entity ? <EntityDisplayName entityRef={entity} hideIcon /> : title}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? 'home'}>
      <Header
        title={<EntityLayoutTitle title={headerTitle} entity={entity} />}
        pageTitleOverride={headerTitle}
        type={headerType}
        subtitle={
          parentEntity && (
            <Breadcrumbs separator=">" className={classes.breadcrumbs}>
              {ancestorEntity && (
                <EntityRefLink
                  entityRef={ancestorEntity.targetRef}
                  disableTooltip
                />
              )}
              <EntityRefLink
                entityRef={parentEntity.targetRef}
                disableTooltip
              />
              {name}
            </Breadcrumbs>
          )
        }
      />

      {loading && <Progress />}

      {entity && <RoutedTabs routes={routes} />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && <Content>NotFoundComponent</Content>}
    </Page>
  );
};
