import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import {
  InspectEntityDialog,
  useEntity,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { useNotification, useRefresh, useUser } from '@internal/common';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BugReportIcon from '@mui/icons-material/BugReportOutlined';
import { DeleteEntityDialog } from '../DKTDeleteEntityDialog/DeleteEntityDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Entity } from '@backstage/catalog-model';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import { LinkButton } from '@backstage/core-components';
import { Refresh } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

interface DKTCustomCTAProps {
  kind: string;
}

const useStyles = makeStyles(theme => ({
  cardButton: {
    borderRadius: '5px !important',
    textTransform: 'capitalize',
    marginLeft: '15px !important',
    textDecoration: 'none !important',
    padding: '5px 15px',
    ['&:hover']: {
      backgroundColor: '#E1E3F5 !important',
      color: theme.palette.primary.main + ' !important',
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export const DKTCustomCTA: FC<DKTCustomCTAProps> = ({ kind }) => {
  const { entity } = useEntity<Entity>();
  const [synchroniseUrl, setSynchroniseUrl] = useState<string>('');
  const { synchroniseEntity } = useRefresh(entity.metadata.name, {
    synchroniseUrl,
  });
  const classes = useStyles();
  const buttonClass = classes.cardButton;
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const [inspectOpen, setInspectOpen] = useState(false);
  const [urlAlertState, setUrlAlertState] = useState(false);
  const { setNotification } = useNotification();
  const [openDialog, setOpenDialog] = useState(false);
  const { isAdmin } = useUser();
  const entityBaseUrl =
    kind === 'system'
      ? `system:default/${entity.metadata.name}`
      : `component:default/${entity.metadata.name}`;
  const editUrl = `/register/${kind === 'system' ? 'systems' : 'components'}/${
    entity.metadata.name
  }/${kind === 'system' ? 'edit' : ''}`;

  const onDelete = () => {
    setOpenDialog(true);
  };

  const s = () => {
    return synchroniseEntity();
  };

  useEffect(() => {
    if (kind === 'system') {
      setSynchroniseUrl('/api/catalog/systems/');
    } else {
      setSynchroniseUrl('/api/catalog/components/');
    }
  }, [kind]);

  useEffect(() => {
    if (urlAlertState) {
      setNotification({
        message: 'URL copied to clipboard',
        severity: 'success',
      });
      setUrlAlertState(false);
    }
  }, [urlAlertState, setNotification]);

  return (
    <Box sx={{ position: 'relative' }}>
      <InspectEntityDialog
        open={inspectOpen}
        onClose={() => setInspectOpen(false)}
        entity={entity}
      />

      <Box
        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
      >
        <Tooltip title={'Bookmark entity'}>
          <IconButton
            color="primary"
            onClick={() => {
              toggleStarredEntity(entityBaseUrl);
            }}
          >
            {isStarredEntity(entityBaseUrl) ? (
              <BookmarkIcon
                data-testid="entity-bookmark-icon"
                sx={{
                  color: '#FF9800',
                }}
              />
            ) : (
              <BookmarkBorderIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={'Copy entity URL'}>
          <IconButton
            color="primary"
            sx={{ marginLeft: '15px' }}
            onClick={() => {
              setUrlAlertState(true);
              navigator.clipboard.writeText(window.location.href);
            }}
          >
            <FileCopyIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Inspect entity'}>
          <IconButton
            color="primary"
            sx={{ marginLeft: '15px' }}
            onClick={() => setInspectOpen(true)}
          >
            <BugReportIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Refresh ${kind}`}>
          <IconButton color="primary" sx={{ marginLeft: '15px' }} onClick={s}>
            <Refresh color="primary" />
          </IconButton>
        </Tooltip>
        <LinkButton
          to={editUrl}
          className={buttonClass}
          size="large"
          variant="contained"
          color="primary"
        >
          <EditIcon
            fontSize="inherit"
            sx={{ marginRight: '5px', fontSize: '24px' }}
          />
          Edit
        </LinkButton>
        <Button
          startIcon={
            <DeleteOutlineIcon
              fontSize="inherit"
              sx={{ marginRight: '5px', fontSize: '24px' }}
            />
          }
          className={buttonClass}
          size="large"
          variant="contained"
          color="error"
          onClick={onDelete}
          disabled={!isAdmin}
        >
          Delete {kind === 'system' ? 'System' : 'Component'}
        </Button>
      </Box>

      <DeleteEntityDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        entity={entity}
        kind={kind}
        redirect={true}
      />
    </Box>
  );
};
