import { Typography } from '@mui/material';
import { ComponentEntity } from '@backstage/catalog-model';
import {
  EntityDisplayName,
  EntityRefLink,
  EntityTable,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import {
  CodeSnippet,
  InfoCard,
  InfoCardVariants,
  Progress,
  TableColumn,
  WarningPanel,
} from '@backstage/core-components';
import { useState } from 'react';

export const ProvidingOrConsumingComponentsCard = (props: {
  variant?: InfoCardVariants;
  loading?: boolean;
  error?: any;
  title: string;
  filterType: string;
  emptyContentMessage: string;
}) => {
  const {
    variant = 'gridItem',
    loading = false,
    error,
    title,
    filterType,
    emptyContentMessage,
  } = props;

  const { entity } = useEntity();
  const [currentEntity] = useState(entity);
  const { entities } = useRelatedEntities(currentEntity, {
    type: filterType,
    kind: 'component',
  });

  const columns: TableColumn<ComponentEntity>[] = [];
  columns.push({
    title: 'Name',
    render: (row: any) => {
      const entityRef = {
        kind: 'component',
        namespace: 'default',
        name: row.metadata.name,
      };
      return row.metadata.name ? (
        <EntityRefLink entityRef={entityRef}>
          <EntityDisplayName hideIcon={true} entityRef={entityRef} />
        </EntityRefLink>
      ) : (
        <div>-</div>
      );
    },
  });
  columns.push({
    title: 'System',
    render: (row: any) => {
      const entityRef = {
        kind: 'system',
        namespace: 'default',
        name: row.spec.system,
      };
      return row.spec.system ? (
        <EntityRefLink entityRef={entityRef}>
          <EntityDisplayName hideIcon={true} entityRef={entityRef} />
        </EntityRefLink>
      ) : (
        <div>-</div>
      );
    },
  });
  columns.push({
    title: 'Owner',
    render: (row: any) =>
      !row.spec.owner.includes('null') ? (
        <EntityRefLink entityRef={row.spec.owner} defaultKind="group" />
      ) : (
        <div>-</div>
      ),
  });
  columns.push({ title: 'Type', field: 'spec.type' });
  columns.push({ title: 'Lifecycle', field: 'spec.lifecycle' });
  columns.push({ title: 'Description', field: 'metadata.description' });
  const tableOptions = {};

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error || !entities) {
    return (
      <InfoCard variant={variant} title={title}>
        <WarningPanel
          severity="error"
          title="Could not load Components"
          message={<CodeSnippet text={`${error}`} language="text" />}
        />
      </InfoCard>
    );
  }

  return (
    <EntityTable
      title={title}
      variant={variant}
      emptyContent={
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">{emptyContentMessage}</Typography>
        </div>
      }
      columns={columns}
      tableOptions={tableOptions}
      entities={entities as ComponentEntity[]}
    />
  );
};
