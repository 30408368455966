import { ApiDefinitionDialog, ApiTypeTitle } from '@backstage/plugin-api-docs';

import { ApiEntity } from '@backstage/catalog-model';
import ApiIcon from '@mui/icons-material/Api';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { ToggleButton } from '@mui/material';
import { useState } from 'react';

export function createSpecApiTypeColumn(): TableColumn<ApiEntity> {
  return {
    title: 'Type',
    field: 'spec.type',
    render: entity => <ApiTypeTitle apiEntity={entity} />,
  };
}

const ApiDefinitionButton = ({ apiEntity }: { apiEntity: ApiEntity }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <ToggleButton
        aria-label="Toggle API Definition Dialog"
        onClick={() => setDialogOpen(!dialogOpen)}
        value={dialogOpen}
      >
        <ApiIcon />
      </ToggleButton>
      <ApiDefinitionDialog
        entity={apiEntity}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

function createApiDefinitionColumn(): TableColumn<ApiEntity> {
  return {
    title: 'API Definition',
    render: entity => <ApiDefinitionButton apiEntity={entity} />,
  };
}

export const apiEntityColumns: TableColumn<ApiEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'API' }),
  EntityTable.columns.createOwnerColumn(),
  createSpecApiTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
  createApiDefinitionColumn(),
];
