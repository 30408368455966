import { Alert } from '@mui/material';
import { AlertMessage } from '@backstage/core-plugin-api';
import { Snackbar } from '@material-ui/core';

interface SnackBarProps {
  message: AlertMessage | undefined;
  // eslint-disable-next-line no-unused-vars
  setMessage: (message: AlertMessage | undefined) => void;
}
export const SnackBar = ({ message, setMessage }: SnackBarProps) => {
  return (
    <Snackbar
      role="alert"
      open={!!message}
      autoHideDuration={6000}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      onClose={() => setMessage(undefined)}
    >
      <Alert
        onClose={() => setMessage(undefined)}
        severity={message?.severity ?? 'info'}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message?.message}
      </Alert>
    </Snackbar>
  );
};
