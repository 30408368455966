import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
} from '@mui/material';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import {
  CompoundEntityRef,
  DEFAULT_NAMESPACE,
  parseEntityRef,
} from '@backstage/catalog-model';
import {
  HeaderIconLinkRow,
  IconLinkVerticalProps,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import {
  ScmIntegrationIcon,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  configApiRef,
  createExternalRouteRef,
  useApi,
  useApp,
  useRouteRef,
} from '@backstage/core-plugin-api';
import {
  getEntitySourceLocation,
  useEntity,
} from '@backstage/plugin-catalog-react';

import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import { DktEntityAboutContent } from './DktEntityAboutContent';
import DocsIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import { FC } from 'react';
import { isTemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';

const TECHDOCS_ANNOTATION = 'backstage.io/techdocs-ref';

const TECHDOCS_EXTERNAL_ANNOTATION = 'backstage.io/techdocs-entity';

export const viewTechDocRouteRef = createExternalRouteRef({
  id: 'view-techdoc',
  optional: true,
  params: ['namespace', 'kind', 'name'],
});

export const createFromTemplateRouteRef = createExternalRouteRef({
  id: 'create-from-template',
  optional: true,
  params: ['namespace', 'templateName'],
});

const classes = {
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
};

export interface DktEntityAboutCardProps {
  variant?: InfoCardVariants;
}

export const DktEntityAboutCard: FC<DktEntityAboutCardProps> = ({
  variant,
}) => {
  const app = useApp();
  const config = useApi(configApiRef);

  const { entity } = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const viewTechdocLink = useRouteRef(viewTechDocRouteRef);
  const templateRoute = useRouteRef(createFromTemplateRouteRef);

  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );

  let techdocsRef: CompoundEntityRef | undefined;

  if (entity.metadata.annotations?.[TECHDOCS_EXTERNAL_ANNOTATION]) {
    techdocsRef = parseEntityRef(
      entity.metadata.annotations?.[TECHDOCS_EXTERNAL_ANNOTATION],
    );
  }

  const viewInSource: IconLinkVerticalProps = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon: <ScmIntegrationIcon type={entitySourceLocation?.integrationType} />,
    href: entitySourceLocation?.locationTargetUrl,
  };
  const viewInTechDocs: IconLinkVerticalProps = {
    label: 'View TechDocs',
    disabled:
      !(
        entity.metadata.annotations?.[TECHDOCS_ANNOTATION] ||
        entity.metadata.annotations?.[TECHDOCS_EXTERNAL_ANNOTATION]
      ) || !viewTechdocLink,
    icon: <DocsIcon />,
    href:
      viewTechdocLink &&
      (techdocsRef
        ? viewTechdocLink({
            namespace: techdocsRef.namespace || DEFAULT_NAMESPACE,
            kind: techdocsRef.kind,
            name: techdocsRef.name,
          })
        : viewTechdocLink({
            namespace: entity.metadata.namespace || DEFAULT_NAMESPACE,
            kind: entity.kind,
            name: entity.metadata.name,
          })),
  };

  const subscribeApi: IconLinkVerticalProps = {
    label: 'Subscribe API',
    icon: <AddAlertOutlinedIcon />,
    href: `${config.getString('graviteeFront.portalUrl')}/catalog/api/${
      entity.metadata.name
    }/subscribe`,
  };

  const subHeaderLinks = [viewInSource, viewInTechDocs];
  if (entity.kind === 'API') {
    subHeaderLinks.push(subscribeApi);
  }

  if (isTemplateEntityV1beta3(entity)) {
    const Icon = app.getSystemIcon('scaffolder') ?? CreateComponentIcon;

    const launchTemplate: IconLinkVerticalProps = {
      label: 'Launch Template',
      icon: <Icon />,
      disabled: !templateRoute,
      href:
        templateRoute &&
        templateRoute({
          templateName: entity.metadata.name,
          namespace: entity.metadata.namespace || DEFAULT_NAMESPACE,
        }),
    };
    subHeaderLinks.push(launchTemplate);
  }

  let cardClass;
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass;
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  return (
    <Card sx={cardClass} data-testid="about-card">
      <CardHeader
        title="About"
        action={
          entity.kind !== 'Group' &&
          entity.kind !== 'API' && (
            <IconButton
              component={Link}
              aria-label="Edit"
              title="Edit Component"
              to={
                entity?.metadata?.name
                  ? `/register/components/${entity.metadata.name}`
                  : '#'
              }
            >
              <EditIcon />
            </IconButton>
          )
        }
        subheader={<HeaderIconLinkRow links={subHeaderLinks} />}
      />
      <Divider />
      <CardContent sx={cardContentClass}>
        <DktEntityAboutContent entity={entity} />
      </CardContent>
    </Card>
  );
};
