import { CardTab, TabbedCard } from '@backstage/core-components';

type OpenapiCardProps = { error: string };

export function ErrorTabbedCard({ error }: Readonly<OpenapiCardProps>) {
  return (
    <TabbedCard title={'openapi'}>
      {[
        <CardTab key="error" label={'Error'}>
          {error}
        </CardTab>,
      ]}
    </TabbedCard>
  );
}
