import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { IconButton, Link } from '@mui/material';

import { DeleteOutline } from '@mui/icons-material';
import { EntityRefLink } from '@backstage/plugin-catalog-react';

export type CustomGridRow = GridRenderCellParams<
  any,
  any,
  any,
  GridTreeNodeWithRender
>;

export const getApiNameCell = ({ row }: CustomGridRow) => {
  let link;

  if (row.title) {
    link = (
      <Link
        sx={{ fontWeight: 'bold' }}
        href={`/catalog/default/api/${row.name}`}
        target="_blank"
      >
        {row.title}
      </Link>
    );
  } else {
    link = <div></div>;
  }
  return link;
};

export const getSystemCell = ({ row }: CustomGridRow) => {
  let link;
  if (row.system) {
    link = <EntityRefLink entityRef={`system:default/${row.system}`} />;
  } else {
    link = <div></div>;
  }
  return link;
};

export const getOwnerCell = ({ row }: CustomGridRow) => {
  const entityRef = {
    kind: 'Group',
    namespace: 'default',
    name: row.owner?.replaceAll(`group:default/`, ''),
  };
  return row.owner ? (
    <EntityRefLink entityRef={entityRef} defaultKind="group" />
  ) : (
    <div></div>
  );
};

export const getLifecycleCell = ({ row }: CustomGridRow) => {
  return row.lifecycle ? row.lifecycle : '-';
};

export const getDescsriptionCell = ({ row }: CustomGridRow) => {
  return row.description ?? '-';
};
export const getRemoveCell = (
  { row }: CustomGridRow,
  // eslint-disable-next-line no-unused-vars
  onRemove: (apiName: string) => void,
) => {
  return (
    <IconButton aria-label="remove" onClick={() => onRemove(row.name)}>
      <DeleteOutline />
    </IconButton>
  );
};
